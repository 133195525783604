import styled, { CSSObject } from 'styled-components';
import { palette } from 'workmotion-design-system';

export const AiAssistantTextInputContainer = styled.div({
    position: 'sticky',
    bottom: 0,
    background: palette.secondary.white,
});

export const StyledTextInputContainer = styled.div({
    padding: '1.5rem',
    borderTop: `1px solid ${palette.greyscale.antiFlashWhite}`,
    position: 'relative',
    background: palette.secondary.white,
});

export const TEXT_AREA_STYLES: CSSObject = {
    height: 'auto',
    paddingRight: '3rem',
    maxWidth: 'initial',
};

export const StyledSendIconContainer = styled.div({
    position: 'absolute',
    top: '2.25rem',
    right: '2.25rem',
    ':hover': {
        opacity: 0.9,
    },
});

export const SEND_ICON_ENABLED_STYLES: CSSObject = {
    fontSize: '1.5rem',
    color: palette.primary.genericViridian,
    cursor: 'pointer',
};

export const SEND_ICON_DISABLED_STYLES: CSSObject = {
    fontSize: '1.5rem',
    color: palette.greyscale.UCLABlue,
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'auto',
};

export const DISABLED_STYLES: CSSObject = {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'auto',
};

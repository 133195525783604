import React, { useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { palette } from 'workmotion-design-system';

import { AiAssistantMessage } from 'ai-assistant/ai-assistant.types';
import { useSendFeedbackOnMessage } from 'ai-assistant/hooks/useLikeMessage';

import {
    StyledActionsContainer,
    StyledIconContainer,
} from './actions-on-message.styles';

export const ActionsOnMessage = (props: AiAssistantMessage) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = (message: string) => {
        navigator.clipboard.writeText(message);
        setIsCopied(true);

        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 3000);

        return () => clearTimeout(timeout);
    };

    const { sendLikeOnMessage, sendDisLikeOnMessage } =
        useSendFeedbackOnMessage(props.id);

    return (
        <StyledActionsContainer>
            {isCopied ? (
                <StyledIconContainer style={{ pointerEvents: 'none' }}>
                    <DoneIcon
                        style={{
                            color: palette.primary.genericViridian,
                            fontSize: '1.5rem',
                        }}
                    />
                </StyledIconContainer>
            ) : (
                <StyledIconContainer onClick={() => handleCopy(props.content)}>
                    <FileCopyIcon />
                </StyledIconContainer>
            )}

            {props.isLike !== true && props.isLike !== false && (
                <>
                    <StyledIconContainer onClick={() => sendLikeOnMessage()}>
                        <ThumbUpIcon />
                    </StyledIconContainer>

                    <StyledIconContainer onClick={() => sendDisLikeOnMessage()}>
                        <ThumbDownIcon />
                    </StyledIconContainer>
                </>
            )}

            {props.isLike === true && (
                <StyledIconContainer style={{ pointerEvents: 'none' }}>
                    <ThumbUpIcon
                        style={{
                            color: palette.primary.genericViridian,
                        }}
                    />
                </StyledIconContainer>
            )}

            {props.isLike === false && (
                <StyledIconContainer style={{ pointerEvents: 'none' }}>
                    <ThumbDownIcon
                        style={{
                            color: palette.primary.genericViridian,
                        }}
                    />
                </StyledIconContainer>
            )}
        </StyledActionsContainer>
    );
};

import React, { Suspense } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { AuthenticationWrapper } from '@workmotion/frontend-auth-library';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Spinner } from 'workmotion-design-system';

import { ErrorBoundary } from 'error-tracking/error-boundary';

import { AppRoutes } from './app-routes';

Modal.setAppElement('#monolith');

export const AiAssistantApp: React.FC = () => (
    <Suspense fallback={<Spinner />}>
        <AuthenticationWrapper>
            <ErrorBoundary>
                <Router>
                    <AppRoutes />
                </Router>
            </ErrorBoundary>
        </AuthenticationWrapper>

        <ToastContainer />
    </Suspense>
);

import { AccessToken } from '@workmotion/frontend-auth-library';

import { oktaAuth } from 'helpers/okta-instance';

export const getConfig = async () => {
    const accessTokenData = (await oktaAuth.tokenManager.get(
        'accessToken'
    )) as AccessToken;

    return {
        headers: {
            Authorization: `Bearer ${accessTokenData?.accessToken}`,
            'Content-Type': 'application/json',
        },
    };
};

import styled, { CSSObject } from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledIncomingMessageContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: '1rem',
    border: `1px solid ${palette.tertiary.aquaHaze}`,
    borderRadius: '1.5rem',
    width: 'fit-content',
    overflowWrap: 'anywhere',
});

export const StyledTitleContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

export const StyledIncomingMessage = styled.pre({
    lineHeight: '1.5rem',
    color: palette.greyscale.persianBlue,
    fontSize: '0.875rem',
    whiteSpace: 'pre-wrap',
});

export const StyledTime = styled.pre({
    color: palette.greyscale.UCLABlue,
    fontSize: '0.75rem',
});

export const StyledMessagesContainer = styled.div({
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
});

export const defaultMarkDownStyles: CSSObject = {
    '& p': {
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 400,
        display: 'inline',
    },
    '& ol': {
        paddingLeft: '1.25rem',
        marginBlockEnd: 0,
    },
    '& ul': {
        display: 'block',
        listStyle: 'none',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: '0rem',
    },
    '& li': {
        marginBottom: '0.625rem',
        display: 'flex',
        flexDirection: 'column',
        '::before': {
            content: 'none',
            fontSize: '1.5rem',
            marginRight: '0.25rem',
        },
        '& ul': {
            marginTop: 0,
            marginBottom: 0,
            marginLeft: '0.5rem',
            '& li': {
                marginBottom: 0,
            },
        },
        '& p': {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
};

import React, { useCallback, useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import moment from 'moment';
import { Markdown } from 'workmotion-design-system';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { AiAssistantMessage } from 'ai-assistant/ai-assistant.types';
import { useSendNewMessage } from 'ai-assistant/hooks/useSendNewMessage';
import { WomoLogo } from 'assets/womo-logo';
import { en } from 'localization';

import { createGMTDate } from '../chat-area.helper';
import { ActionsOnMessage } from './actions-on-message/actions-on-message';
import {
    defaultMarkDownStyles,
    StyledIncomingMessage,
    StyledIncomingMessageContainer,
    StyledMessagesContainer,
    StyledTime,
    StyledTitleContainer,
} from './incoming-message.styles';
import { MessageChip } from './message-chip/message-chip';

const {
    aiAssistantDrawer: {
        quickMessage: { carryoverDaysInSpain, changePayment, approveExpense },
    },
} = en;

const QUICK_MESSAGES = [carryoverDaysInSpain, approveExpense, changePayment];

export const IncomingMessage = (props: AiAssistantMessage) => {
    const { sendNewMessage } = useSendNewMessage();
    const [isAssistantTyping] = useAtom(AiAssistantAtoms.isAssistantTyping);

    const calculateRelativeTime = useCallback(
        () =>
            props.isWelcomeMessage
                ? moment(props.created_at).fromNow()
                : moment(createGMTDate(props.created_at)).fromNow(),
        [props.created_at, props.isWelcomeMessage]
    );

    const [relativeTime, setRelativeTime] = useState(() =>
        calculateRelativeTime()
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRelativeTime(calculateRelativeTime());
        }, 60000);

        return () => clearInterval(intervalId);
    }, [calculateRelativeTime, props.created_at, props.isWelcomeMessage]);

    const handleOnChipClick = (message: string) => {
        if (isAssistantTyping) {
            return;
        }
        sendNewMessage(message);
    };

    return (
        <StyledIncomingMessageContainer>
            <StyledTitleContainer>
                <WomoLogo />

                <StyledTime>{relativeTime}</StyledTime>
            </StyledTitleContainer>

            <StyledIncomingMessage>
                <Markdown
                    text={props.content}
                    styles={{
                        ...defaultMarkDownStyles,
                    }}
                    reactMarkdownProps={{ linkTarget: '_blank' }}
                />
            </StyledIncomingMessage>

            {props.isWelcomeMessage ? (
                <StyledMessagesContainer>
                    {QUICK_MESSAGES.map(quickMessage => (
                        <MessageChip
                            message={quickMessage}
                            key={quickMessage}
                            onClick={() => handleOnChipClick(quickMessage)}
                        />
                    ))}
                </StyledMessagesContainer>
            ) : (
                <ActionsOnMessage {...props} />
            )}
        </StyledIncomingMessageContainer>
    );
};

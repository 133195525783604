import React from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';

import { AiAssistantButton } from './ai-assistant-button/ai-assistant-button';
import { AiAssistantDrawer } from './ai-assistant-drawer/ai-assistant-drawer';

export const AiAssistant = () => {
    const {
        userIdentityInfo: { isHrManager, isAuthenticated },
    } = useUserIdentity();

    if (!isHrManager || !isAuthenticated) {
        return <></>;
    }

    return (
        <>
            <AiAssistantButton />

            <AiAssistantDrawer />
        </>
    );
};

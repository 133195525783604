import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledOutgoingMessageContainer = styled.div({
    display: 'flex',
    justifyContent: 'end',
});

export const StyledMessageWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
});

export const StyledOutgoingMessage = styled.pre({
    padding: '1rem',
    lineHeight: '1.5rem',
    background: palette.greyscale.ghostWhite,
    color: palette.greyscale.UCLABlue,
    fontSize: '0.875rem',
    border: `1px solid ${palette.greyscale.antiFlashWhite}`,
    borderRadius: '1rem',
    whiteSpace: 'pre-wrap',
    alignSelf: 'end',
});

export const StyledError = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    color: palette.semantic.sunsetOrange,
    fontSize: '0.75rem',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
});

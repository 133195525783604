import { useCallback } from 'react';

import axios from 'axios';
import { useAtom } from 'jotai';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { SendFeedbackDTO } from 'ai-assistant/ai-assistant.types';
import { API_URL } from 'api/api-url.constant';
import { getConfig } from 'api/api.helpers';

export const useSendFeedbackOnMessage = (messageId: string) => {
    const [chatHistory, setChatHistory] = useAtom(AiAssistantAtoms.chatHistory);

    const sendFeedbackOnMessage = useCallback(
        async (isLike: boolean) => {
            if (!messageId || !chatHistory || chatHistory.length === 0) {
                return;
            }

            try {
                const updatedChatHistory = chatHistory.map(message => {
                    if (message.id === messageId) {
                        return {
                            ...message,
                            isLike: isLike,
                        };
                    }

                    return message;
                });

                setChatHistory(updatedChatHistory);

                const requestBody: SendFeedbackDTO = {
                    message_id: messageId,
                    is_like: isLike,
                };

                const res = await axios.put(
                    API_URL.FEEDBACK,
                    requestBody,
                    await getConfig()
                );

                return res.data;
            } catch (error) {
                console.error('Error making post request:', error);
            }
        },
        [chatHistory, messageId, setChatHistory]
    );

    return {
        sendLikeOnMessage: () => sendFeedbackOnMessage(true),
        sendDisLikeOnMessage: () => sendFeedbackOnMessage(false),
    };
};

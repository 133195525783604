import React, { useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { useAtom } from 'jotai';
import { Textarea } from 'workmotion-design-system';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { useSendNewMessage } from 'ai-assistant/hooks/useSendNewMessage';
import { BubbleChatLottie } from 'assets/lottie/bubble-chat-lottie/bubble-chat-lottie';
import { en } from 'localization';

import { isOnlyWhitespaceOrNotValid } from './ai-assistant-text-input.helper';
import {
    StyledSendIconContainer,
    AiAssistantTextInputContainer,
    TEXT_AREA_STYLES,
    StyledTextInputContainer,
    SEND_ICON_DISABLED_STYLES,
    SEND_ICON_ENABLED_STYLES,
} from './ai-assistant-text-input.styles';

const {
    aiAssistantDrawer: { textInputPlaceholder },
} = en;

export const AiAssistantTextInput = () => {
    const [inputMessage, setInputMessage] = useState('');
    const { sendNewMessage } = useSendNewMessage();

    const [isAssistantTyping] = useAtom(AiAssistantAtoms.isAssistantTyping);

    const handleSendMessage = async () => {
        if (isOnlyWhitespaceOrNotValid(inputMessage)) {
            return;
        }

        if (isAssistantTyping) {
            return;
        }

        sendNewMessage(inputMessage);

        setTimeout(() => {
            setInputMessage('');
        }, 1);
    };

    const handleSendMessageOnEnter = (
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (isAssistantTyping) {
            return;
        }

        if (e.key === 'Enter') {
            if (isOnlyWhitespaceOrNotValid(inputMessage)) {
                e.preventDefault();

                return;
            }

            if (!e.shiftKey) {
                handleSendMessage();
                setInputMessage('');
                e.preventDefault();
            }
        }
    };

    return (
        <AiAssistantTextInputContainer>
            {isAssistantTyping && (
                <BubbleChatLottie
                    customStyles={{
                        margin: '0 0 1.5rem 1.5rem',
                        opacity: 0.5,
                    }}
                />
            )}

            <StyledTextInputContainer>
                <Textarea
                    placeholder={textInputPlaceholder}
                    style={TEXT_AREA_STYLES}
                    rows={1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputMessage(e.target.value);
                    }}
                    value={inputMessage}
                    onKeyDown={handleSendMessageOnEnter}
                />

                <StyledSendIconContainer>
                    <SendIcon
                        style={
                            isOnlyWhitespaceOrNotValid(inputMessage) ||
                            isAssistantTyping
                                ? SEND_ICON_DISABLED_STYLES
                                : SEND_ICON_ENABLED_STYLES
                        }
                        onClick={() =>
                            isOnlyWhitespaceOrNotValid(inputMessage) ||
                            isAssistantTyping
                                ? {}
                                : handleSendMessage()
                        }
                    />
                </StyledSendIconContainer>
            </StyledTextInputContainer>
        </AiAssistantTextInputContainer>
    );
};

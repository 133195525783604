import React from 'react';

import { useAtom } from 'jotai';
import { Drawer } from 'workmotion-design-system';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { en } from 'localization';

import { StyledAiAssistantDrawerContainer } from './ai-assistant-drawer.styles';
import { AiAssistantTextInput } from './ai-assistant-text-input/ai-assistant-text-input';
import { ChatArea } from './chat-area/chat-area';

const {
    aiAssistantDrawer: { title, titleTooltip },
} = en;

export const AiAssistantDrawer = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useAtom(
        AiAssistantAtoms.isDrawerOpen
    );

    return (
        <Drawer
            open={isDrawerOpen}
            title={title}
            tooltip={{
                text: <div style={{ width: '12rem' }}>{titleTooltip}</div>,
            }}
            anchor="right"
            onClose={() => setIsDrawerOpen(false)}
            keepMounted={true}
        >
            <StyledAiAssistantDrawerContainer data-testid="ai-assistant-drawer-elements">
                <ChatArea />

                <AiAssistantTextInput />
            </StyledAiAssistantDrawerContainer>
        </Drawer>
    );
};

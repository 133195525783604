import React from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import { useAtom, useSetAtom } from 'jotai';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { AiAssistantMessage } from 'ai-assistant/ai-assistant.types';
import { useSendNewMessage } from 'ai-assistant/hooks/useSendNewMessage';
import { en } from 'localization';

import {
    StyledError,
    StyledMessageWrapper,
    StyledOutgoingMessage,
    StyledOutgoingMessageContainer,
} from './outgoing-message.styles';

const {
    aiAssistantDrawer: { failedToSend },
} = en;

export const OutgoingMessage = (props: AiAssistantMessage) => {
    const [isAssistantTyping] = useAtom(AiAssistantAtoms.isAssistantTyping);
    const setChatHistory = useSetAtom(AiAssistantAtoms.chatHistory);

    const { sendNewMessage } = useSendNewMessage();

    const handleRetry = () => {
        if (isAssistantTyping) {
            return;
        }

        setChatHistory(prevMessages =>
            prevMessages.filter(message => message.id !== props.id)
        );

        sendNewMessage(props.content);
    };

    return (
        <StyledOutgoingMessageContainer>
            <StyledMessageWrapper>
                <StyledOutgoingMessage>{props.content}</StyledOutgoingMessage>

                {props.failedToSend && (
                    <StyledError onClick={handleRetry}>
                        <span>{failedToSend}</span>

                        <ReplayIcon style={{ fontSize: '0.875rem' }} />
                    </StyledError>
                )}
            </StyledMessageWrapper>
        </StyledOutgoingMessageContainer>
    );
};

export enum MessageRole {
    assistant = 'assistant',
    user = 'user',
}

export interface AiAssistantMessage {
    content: string;
    role?: MessageRole;
    created_at?: string | any;
    id: string;
    isLike?: boolean;
    isWelcomeMessage?: boolean;
    failedToSend?: boolean;
}

export interface SendQuestionDTO {
    question: string;
}

export interface SendFeedbackDTO {
    message_id: string;
    is_like: boolean;
}

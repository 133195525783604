import React from 'react';

import Lottie from 'react-lottie-player';
import { CSSObject } from 'styled-components';

import bubbleChatLottieData from './bubble-chat-lottie-data.json';

interface BubbleChatLottieProps {
    width?: string;
    height?: string;
    customStyles?: CSSObject;
}

export const BubbleChatLottie: React.FC<BubbleChatLottieProps> = ({
    width = '3rem',
    height = 'auto',
    customStyles,
}) => (
    <div
        style={{
            width,
            height,
            pointerEvents: 'none',
            ...customStyles,
        }}
    >
        <Lottie
            loop
            play
            animationData={bubbleChatLottieData}
            rendererSettings={{
                preserveAspectRatio: 'xMidYMid slice',
            }}
        />
    </div>
);

import React from 'react';

import styled, { CSSObject } from 'styled-components';
import { InfoBadge, palette } from 'workmotion-design-system';

interface MessageChipProps {
    message: string;
    onClick: () => void;
}

const CHIP_STYLES: CSSObject = {
    border: 'none',
    borderRadius: '1rem',
    minWidth: '6.5rem',
    cursor: 'pointer',
};

const StyledInfoBadge = styled(InfoBadge)({
    '&:hover': {
        background: `${palette.secondary.softMint} !important`,
    },
});

export const MessageChip = (props: MessageChipProps) => (
    <StyledInfoBadge
        label={<div style={{ fontWeight: 400 }}>{props.message}</div>}
        color={'green'}
        size="medium"
        customChipStyle={CHIP_STYLES}
        data-testid="message-chip"
        onClick={props.onClick}
    />
);

import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledActionsContainer = styled.div({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.25rem',
});

export const StyledIconContainer = styled.div({
    borderRadius: '0.25rem',
    cursor: 'pointer',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
        color: palette.greyscale.UCLABlue,
        fontSize: '1rem',
    },
    '&:hover': {
        background: palette.tertiary.aquaHaze,
    },
    '&:hover svg': {
        color: palette.primary.genericViridian,
    },
});

import React from 'react';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useAtom, useSetAtom } from 'jotai';
import { Button, palette } from 'workmotion-design-system';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import {
    AiAssistantMessage,
    MessageRole,
} from 'ai-assistant/ai-assistant.types';
import { en } from 'localization';

import {
    StyledAnimatedWrapper,
    StyledAiAssistantButtonContainer,
} from './ai-assistant-button.styles';

const {
    aiAssistantButton: { title },
    aiAssistantDrawer: { welcomeMessage },
} = en;

export const AiAssistantButton = () => {
    const setIsDrawerOpen = useSetAtom(AiAssistantAtoms.isDrawerOpen);

    const [chatHistory, setChatHistory] = useAtom(AiAssistantAtoms.chatHistory);

    const handleClick = () => {
        if (chatHistory?.length < 1) {
            const welcomeMessageData: AiAssistantMessage = {
                content: welcomeMessage,
                role: MessageRole.assistant,
                created_at: new Date().toISOString(),
                isWelcomeMessage: true,
                id: String(Date.now()),
            };

            setChatHistory([welcomeMessageData]);
        }
        setIsDrawerOpen(true);
    };

    return (
        <StyledAiAssistantButtonContainer>
            <StyledAnimatedWrapper>
                <Button
                    onClick={handleClick}
                    style={{
                        padding: '0rem 0.8rem',
                        background: `linear-gradient(to right, ${palette.primary.midnightGreen}, ${palette.primary.genericViridian})`,
                    }}
                >
                    <AutoAwesomeIcon
                        style={{ fontSize: '1rem', marginRight: '0.5rem' }}
                    />

                    {title}
                </Button>
            </StyledAnimatedWrapper>
        </StyledAiAssistantButtonContainer>
    );
};

import React, { useRef, useEffect } from 'react';

import { useAtom } from 'jotai';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { MessageRole } from 'ai-assistant/ai-assistant.types';

import { ChatAreaContainer } from './chat-area.styles';
import { IncomingMessage } from './incoming-message/incoming-message';
import { OutgoingMessage } from './outgoing-message/outgoing-message';

export const ChatArea = () => {
    const [chatHistory] = useAtom(AiAssistantAtoms.chatHistory);
    const chatEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    }, [chatHistory?.length]);

    return (
        <ChatAreaContainer>
            {chatHistory?.map((message, index) => (
                <div key={index}>
                    {message.role === MessageRole.assistant ? (
                        <IncomingMessage {...message} />
                    ) : (
                        <OutgoingMessage {...message} />
                    )}
                </div>
            ))}

            <div ref={chatEndRef} />
        </ChatAreaContainer>
    );
};

import React from 'react';

import { useLocation } from 'react-router-dom';

import { AiAssistant } from 'ai-assistant/ai-assistant';

const HIDDEN_PATHS = ['/review-contract'];

export const AppRoutes: React.FC = () => {
    const location = useLocation();

    const shouldHideDiv = HIDDEN_PATHS.some(path =>
        location.pathname.startsWith(path)
    );

    if (shouldHideDiv) {
        return <></>;
    }

    return <AiAssistant />;
};

import { useCallback } from 'react';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import { AiAssistantAtoms } from 'ai-assistant/ai-assistant.atoms';
import { MessageRole, SendQuestionDTO } from 'ai-assistant/ai-assistant.types';
import { API_URL } from 'api/api-url.constant';
import { getConfig } from 'api/api.helpers';

export const useSendNewMessage = () => {
    const setChatHistory = useSetAtom(AiAssistantAtoms.chatHistory);

    const setIsAssistantTyping = useSetAtom(AiAssistantAtoms.isAssistantTyping);

    const sendNewMessage = useCallback(
        async (inputMessage: string) => {
            setIsAssistantTyping(true);

            setChatHistory(prevChatHistory => [
                ...(prevChatHistory || []),
                {
                    content: inputMessage,
                    direction: MessageRole.user,
                    id: String(Date.now()),
                },
            ]);

            try {
                const requestBody: SendQuestionDTO = { question: inputMessage };

                const res = await axios.post(
                    API_URL.ASK,
                    requestBody,
                    await getConfig()
                );

                setChatHistory(prevChatHistory => [
                    ...(prevChatHistory || []),
                    res.data,
                ]);

                setIsAssistantTyping(false);

                return res.data;
            } catch (error) {
                setIsAssistantTyping(false);

                setChatHistory(prevChatHistory => {
                    const updatedChatHistory = [...prevChatHistory];

                    updatedChatHistory[updatedChatHistory.length - 1] = {
                        ...updatedChatHistory[updatedChatHistory.length - 1],
                        failedToSend: true,
                    };

                    return updatedChatHistory;
                });

                console.error('Error making post request:', error);
            }
        },
        [setChatHistory, setIsAssistantTyping]
    );

    return { sendNewMessage };
};

export const en = {
    aiAssistantButton: {
        title: 'AI Assistance',
    },
    aiAssistantDrawer: {
        title: 'WOMO AI Assistance',
        titleTooltip:
            'These Al Assistant answers by WorkMotion are for informational purposes only and do not constitute legal advice; for more details, consult a licensed attorney or open a Help Centre ticket for further assistance.',
        textInputPlaceholder: 'Describe your issue',
        welcomeMessage: `Hi there! l'm here to help with any questions you have about working with us, wherever you are. How can I assist you today?`,
        quickMessage: {
            carryoverDaysInSpain: 'Carryover days in Spain',
            approveExpense: 'Approve expense',
            changePayment: 'Change payment',
        },
        failedToSend: 'Failed, tap to retry',
    },
};

import styled, { keyframes } from 'styled-components';

export const StyledAiAssistantButtonContainer = styled.div({
    position: 'absolute',
    bottom: '2rem',
    right: '1.5rem',
    zIndex: 10,
    k: {
        a: 1,
    },
});

const heartbeat = keyframes`
  0%, 10% { transform: scale(1); }       
  10%, 20% { transform: scale(1.05); }   
  20%, 30% { transform: scale(1); }      
  30%, 40% { transform: scale(1.05); }   
  40%, 50% { transform: scale(1); }      
  50%, 60% { transform: scale(1.05); }   
  60%, 70% { transform: scale(1); }      
  70%, 80% { transform: scale(1.05); }
  80%, 100% { transform: scale(1); }
`;

export const StyledAnimatedWrapper = styled.div`
    animation: ${heartbeat} 10s infinite;
    transform-origin: center;
    display: flex;
    justify-content: center;
`;

export const StyledButtonTitle = styled.span`
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    margin-left: 0.5rem;
`;
